/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: Archivo;
    font-weight: 900;
    src: url("./assets/fonts/Archivo-Black.ttf") format("opentype");
  }
@font-face {
    font-family: Archivo;
    font-weight: 700;
    src: url("./assets/fonts/Archivo-Bold.ttf") format("opentype");
  }
@font-face {
    font-family: Archivo;
    font-weight: 500;
    src: url("./assets/fonts/Archivo-Medium.ttf") format("opentype");
  }
@font-face {
    font-family: Archivo;
    font-weight: 400;
    src: url("./assets/fonts/Archivo-Regular.ttf") format("opentype");
  }
@font-face {
    font-family: Archivo;
    font-weight: 300;
    src: url("./assets/fonts/Archivo-Light.ttf") format("opentype");
  }
@font-face {
    font-family: Archivo;
    font-weight: 100;
    src: url("./assets/fonts/Archivo-Thin.ttf") format("opentype");
  }
@font-face {
    font-family: OneDay;
    font-weight: 400;
    src: url("./assets/fonts/ONEDAY.ttf") format("opentype");
  }

 @layer base{
    body, html {
        font-family: Archivo;
        @apply text-gray-800 text-base lg:text-lg lg:leading-relaxed font-light;

    }

    h1,h2,h3,h4,h5{
      @apply font-black my-1;
    }

    h1 {
      @apply bg-clip-text text-transparent bg-gradient-to-r from-orange-400 via-blue-500 to-blue-400;
      @apply text-3xl lg:text-4xl xl:text-4xl xl:leading-[1.2];
    }

    h2{
      @apply text-xl lg:text-2xl text-orange-400;
    }
    h3{
      @apply text-lg lg:text-xl;
    }
    h3{
      @apply text-sm lg:text-lg;
    }
    h4,h5{
      @apply text-sm font-bold;
    }
    .g-title{
      @apply bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-blue-500;
    }

    main {
      @apply w-full px-12 lg:px-24 mt-24 md:mt-44;
    }
 }


@layer components {
  .btn{
    @apply cursor-pointer px-4 pt-3 pb-2 md:px-6 md:pt-3 md:pb-2 my-4 capitalize font-oneday leading-none text-sm md:text-base lg:text-xl hover:scale-105 ease-in duration-300;
  
    &.primary{
      @apply bg-transparent border-solid border-2 border-light-blue-400 text-light-blue-400 hover:border-orange-400 hover:text-orange-400;
    }

    &.secondary{
      @apply bg-transparent border-solid border-2 border-yellow-400 text-yellow-400 hover:border-dark-blue-950 hover:text-dark-blue-950;
    
      &__fill{
        @apply text-yellow-400 hover:text-blue-950;
      }
    }
    &.tertiary{
      @apply bg-transparent border-solid border-2 border-orange-400 text-orange-400 hover:border-dark-blue-950 hover:text-dark-blue-950;
    }
  }

  .chip {
    @apply text-xs bg-gray-50 text-gray-300 py-2 px-3 leading-none rounded-[4px] mr-2;

    &.cat {
      @apply bg-yellow-300 text-black scale-0 hover:bg-black hover:text-white text-[10px]
             transition-all ease-in-out duration-300;
    }
  }
}

@layer utilities {

  .clippath {
      clip-path: url(#diagonal);
  }

}